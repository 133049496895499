import React from "react";
import RheologyCalculator from "../../components/RheologyCalculator/RheologyCalculator";
import styles from "./Rheology.module.css";

export default function Rheology() {
  return (
    <div className={styles.container} id="rheology-calculator">
      <div className="product-description">
        <h4>Расчет реологический свойств</h4>
      </div>
      <div className={styles.creation_block}>
        <RheologyCalculator />
      </div>
    </div>
  );
}

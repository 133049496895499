export const NavbarDropdownItems = [
  {
    title: "cemlife",
    path: "/cem-life#cem-life",
    cName: "dropdown-link",
  },
  {
    title: "cemtest",
    path: "/cem-test#cem-test",
    cName: "dropdown-link",
  },
  {
    title: "ts-400",
    path: "/ts400#ts400",
    cName: "dropdown-link",
  },
  {
    title: "Инженерное сопровождение",
    path: "/engineering-support#engineering-support",
    cName: "dropdown-link",
  },
  {
    title: "Цем. головы",
    path: "/cem-heads#cem-heads",
    cName: "dropdown-link",
  },
  {
    title: "Обучение персонала",
    path: "/staff-training#staff-training",
    cName: "dropdown-link",
  },
  {
    title: "Расчет реологических свойств",
    path: "/rheology-calculator#rheology-calculator",
    cName: "dropdown-link",
  },
];

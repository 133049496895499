import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hydraulicСalculations from "../../images/hydraulicСalculations.png";
import model3d from "../../images/model3d.png";
import labConnection from "../../images/labConnection.png";
import realTime from "../../images/realtime.gif";
import recalcModule from "../../images/recalcModule.png";

const CarouselCemLife = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">
              Гидравлические расчеты
            </p>
            <p className="product__modules-description-text">
              Расчет зависимости давлений от глубины позволяет определить «зону
              интереса» по ГРП. Расчет ЭЦП позволяет точно подобрать расходы для
              лучшего вытеснения и недопуска ГРП и многие другие расчеты.
            </p>
          </div>
          {/* <img src={hydraulicСalculations} alt="slide-1" />*/}
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">
              3D вытеснение бурового раствора
            </p>
            <p className="product__modules-description-text">
              Наиболее точный алгоритм расчета 3D вытеснения в отрасли помогает
              проанализировать и предотвратить каналообразование. График
              концентрации жидкостей в зоне интереса позволяет поставить тесты
              на совместимость для понимания реального состояния жидкостей в
              скважине.
            </p>
          </div>
          {/*<img src={model3d} alt="slide-2" />*/}
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">
              Связь с лабораторным ПО CMTest
            </p>
            <p className="product__modules-description-text">
              CemLife поддерживает интеграцию следующих данных в CemLife.
              Реологические показания, время загустевания, тесты на
              совместимость. использования данного модуля значительно сокращает
              время подготовки кейса.
            </p>
          </div>
          {/*<img src={labConnection} alt="slide-3" />*/}
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">
              Модуль "Модуль Потоковых вычислений"
            </p>
            <p className="product__modules-description-text">
              Предоставляет возможность подключения к цементировочным агрегатам
              и считывание данных в режиме реального времени.
            </p>
          </div>
          {/*<img src={realTime} alt="slide-4" />*/}
        </div>
      </div>

      <div className="carousel-slide">
        <div className="carousel-slide__content">
          <div className="carousel-slide__desc">
            <p className="product__modules-description-header">
              Модуль перерасчет
            </p>
            <p className="product__modules-description-text">
              Предоставляет инструменты детального анализа проведенной работы по
              реальным данным со скважины. Позволит повысить качество
              цементирования на будущих работах.
            </p>
          </div>
          {/*<img src={recalcModule} alt="slide-4" />*/}
        </div>
      </div>
    </Slider>
  );
};

export default CarouselCemLife;
